<template>
    <div class="userInfo">
        <NavTab title="我的资料" class="tabs"></NavTab>
        <div class="user-content">

          <avater class="avater-img" :avaterImg="userInfos.avatar || defaultImg" width="60px" height="60px"></avater>
          <div class="content-item">
            <div class="content-item-name">用户名</div>
            <div class="content-item-val">{{ userInfos.username }}</div>
          </div>
          <div class="content-item">
            <div class="content-item-name">昵称</div>
            <div class="content-item-val">{{ userInfos.nickname }}</div>
          </div>
          <div class="content-item">
            <div class="content-item-name">UID</div>
            <div class="content-item-val">{{ userInfos.id }}</div>
          </div>
          <div class="content-item">
            <div class="content-item-name">性别</div>
            <div class="content-item-val">{{sexVal}}</div>
          </div>
          <div class="content-item">
            <div class="content-item-name">电话</div>
            <div class="content-item-val">{{userInfos.phone}}</div>
          </div>
        </div>
    </div>
</template>

<script>
import NavTab from '@/components/Mobile/NavTab'
import avater from '@/components/Mobile/avater'
import { mapState } from 'vuex';
export default {
    name: 'UserInfo',
    components:{
        NavTab,
        avater
    },
    data() {
        return {
           // 默认头像
           defaultImg:require('@/assets/images/userLogo.jpg'),
           userInfos:{}
        };
    },
   computed:{
       ...mapState(['userInfo']),
       sexVal()
       {
         if(this.userInfos.sex ===0)
         {
            return '女';
         }
         else if(this.userInfos.sex ===1)
         {
            return '男';
         }
         else{
            return '保密';
         }
       }
   },
   activated()
   {
     this.getUserInfoById();
   },
    mounted() {
        
    },

    methods: {
       // 获取用户信息
   async  getUserInfoById()
     {
        try {
        let { data: res } = await this.api
          .postFormAPISM(
            {
              user_id:Number(this.$route.query.user_id) ||  this.userInfo.id
            },
            "/user/getuserinfobyid"
          )
          let { fans_count, follow_count, user,user_follow_ship } = res.data;
          let { is_follow } = user_follow_ship
          let userInfo = {
                        fans_count: fans_count || 0,
                        follow_count: follow_count || 0,
                        is_follow,
                        ...user,
                    }
             this.userInfos={...userInfo};
      } catch (error) {
        console.log(error);
      }
     },
        
    },
};
</script>

<style lang="less" scoped>
 .userInfo{
    height: 100vh;
    &>.user-content{
        &>.avater-img{
            padding:30px 0;
            text-align: center;
            & /deep/ img{
              object-fit: cover;
            }
        }
        &>.content-item{
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 15px;
            font-size: 14px;
            &>.content-item-name{
                width:70px;
                color: #827d7d;
            }
            &>.content-item-val{
                flex:1;
                padding-left: 40px;
                box-sizing: border-box;
              }
 
            }
        }
    }

</style>